<template>
  <div>
    <div
      class="flex sm:flex-col items-center sm:items-start justify-between my-6 sm:space-y-4"
    >
      <div class="flex space-x-4">
        <div
          class="flex items-center"
          v-for="(item, key) in status"
          :key="item + '-type'"
        >
          <div
            :class="`rounded-full w-3 h-3 bg-promy-${getStatusColor(key)} mr-1`"
          />
          <p class="text-xs text-promy-gray-600 leading-none">{{ item }}</p>
        </div>
      </div>
    </div>
    <div
      class="xl:h-50-screen xl:overflow-y-auto liste-dossier"
      id="list_holder"
    >
      <template v-if="isFetching && current_page == 1">
        <div class="flex justify-center w-full mt-24">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="dossierPromoteur.length > 0">
          <pro-card>
            <pro-table-row :isHeader="true">
              <pro-table-cell class="w-[3%]">
                <Checkbox
                  v-model="checked_all"
                  :val="checked_all"
                  id="all_terrains_brouillon"
                  class="mb-6"
                />
              </pro-table-cell>
              <pro-table-header-cell
                v-for="(sort, index) in sort_list"
                :key="index"
                :class="sort.classes"
                :sortingBy="sorting_by"
                @setSortingBy="setSortingBy"
                @orderBy="orderBy"
                :hasSort="true"
                :title="sort.title"
                :sortKey="sort.sortKey"
              />
            </pro-table-row>
            <pro-table-row
              v-for="(item, index) in dossierPromoteur"
              :key="index"
              :pulsedBorder="{
                hasPulsedBorder: item.has_new_offre,
                color: 'promy-blue',
              }"
              class="relative"
            >
              <pro-table-cell
                :is_checkbox="true"
                label="Séléctionner"
                class="w-[3%] lg:w-full"
              >
                <Checkbox
                  v-model="item.checked"
                  :val="item.checked"
                  :id="`region_${item.id}`"
                  class="mb-6"
                />
              </pro-table-cell>
              <pro-table-cell
                label="Statut"
                class="w-[6%] lg:w-full flex justify-center"
              >
                <div class="flex items-center">
                  <div
                    :class="`rounded-full w-3 h-3 bg-promy-${getStatusColor(
                      item.status,
                    )}`"
                  />
                  <img
                    v-if="item.is_anonymous"
                    src="/images/DossierPromoteur/anonymous-icon.png"
                    class="w-4 h-4 mx-1"
                  />
                </div>
              </pro-table-cell>
              <pro-table-cell label="Avancement" class="w-[8%] lg:w-full">
                <span v-if="item.avancement"> {{ item.avancement }} % </span>
              </pro-table-cell>
              <pro-table-cell label="N° dossier" class="w-[9%] lg:w-full">
                {{ item.dossier_number }}
              </pro-table-cell>
              <pro-table-cell label="Commune" class="w-[16%] lg:w-full">
                {{ item.commune }}
              </pro-table-cell>
              <pro-table-cell label="CP" class="w-[6%] lg:w-full">
                {{ item.code_postal }}
              </pro-table-cell>
              <pro-table-cell label="Ca potentiel" class="w-[8.5%] lg:w-full">
                <span class="font-extrabold"
                  >€ {{ item.ca_potentiel | formatEUR }}</span
                >
              </pro-table-cell>
              <pro-table-cell label="Surf." class="w-[7%] lg:w-full">
                {{ item.surface_terrain | roundDouble }} M²
              </pro-table-cell>
              <pro-table-cell label="Type" class="flex w-[7.5%] lg:w-full">
                {{ item.type_de_projet ? item.type_de_projet : 'N.C' }}
              </pro-table-cell>
              <pro-table-cell label="Offres" class="w-[6.7%] lg:w-full">
                {{ item.nombre_offre ? item.nombre_offre : 0 }}
              </pro-table-cell>
              <pro-table-cell label="Vues" class="w-[5.5%] lg:w-full">
                <PromoteurSeens
                  :vueDossierPromoteur="item.vue_dossier_promoteur"
                  :seensBy="item.seens_by"
                />
              </pro-table-cell>
              <pro-table-cell label="Temps restant" class="w-[13%] lg:w-full">
                ------
              </pro-table-cell>
              <!-- <pro-table-cell class="w-[11.5%]">
                {{ item.responsable ? item.responsable : 'N.C' }}
              </pro-table-cell> -->
              <pro-table-cell class="w-[3.5%] px-0 lg:w-full">
                <div class="flex items-center space-x-4 absolute top-3 right-4">
                  <generatePdfDossierPro :item="item"></generatePdfDossierPro>
                  <ToggleDropUp
                    :item="item"
                    :archivedStatus="archivedStatus"
                    possition="bottom: 25px; right: 25px"
                    @displayArchiveModal="displayModal"
                  />
                </div>
              </pro-table-cell>
            </pro-table-row>
          </pro-card>

          <isVisible :isFetching="isFetching" @isVisible="isVisible" />
        </template>
        <template v-else>
          <p
            class="flex justify-center w-full mt-24 text-lg font-extrabold text-promy-gray-650 font-main"
          >
            Il n'y a pas de dossier pour "{{ filters.search }}"
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers'
import { mapGetters } from 'vuex'
import PromoteurSeens from '@/components/DossierPromoteurs/PromoteurSeens'
import ToggleDropUp from './components/ToggleDropUp'
import generatePdfDossierPro from './components/generatePdfDossierPro'

export default {
  mixins: [helpers],
  components: {
    PromoteurSeens,
    ToggleDropUp,
    generatePdfDossierPro,
  },

  props: {
    filters: {
      type: Object,
      require: true,
    },
    sort_list: {
      type: Array,
      require: true,
    },
    reload_dossier_promoteur: {
      type: Boolean,
      require: true,
    },
    let_save_scroll_position: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      status: {
        2: 'Brouillon',
        3: 'Commercialisation en attente',
      },
      isFetching: false,
      current_page: 1,
      last_page: 1,
      total_page: 1,
      dossierPromoteur: [],
      archivedStatus: [],
      sorting_by: '',
      checked_all: false,
      showAfterSecond: false,
    }
  },

  mounted() {
    this.scrollPositionSaved()
    this.showTimer()
  },

  created() {
    if (this.isDossiersPromoteurSaved) {
      Object.assign(this.$data, this.getDossiersPromoteur)
    } else {
      this.sorting_by = this.sort_list_terrains_by
      this.getDossierPromoteurs(
        this.filters,
        this.sort_list_terrains_by,
        this.sort_list_terrains_by_order,
      )
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.searchAfterDebounce(filters)
      },
    },
    reload_dossier_promoteur(val) {
      if (val) {
        this.current_page = 1
        this.dossierPromoteur = []
        this.getDossierPromoteurs(
          this.filters,
          this.sort_list_terrains_by,
          this.sort_list_terrains_by_order,
        )
      }
    },
    let_save_scroll_position(val) {
      if (val) {
        const dossiersPromoteursDOM = document.getElementById('list_holder')

        if (
          dossiersPromoteursDOM.scrollLeft !== 0 ||
          dossiersPromoteursDOM.scrollTop !== 0
        ) {
          this.$store.commit(
            'userNavigationState/SET_DOSSIERS_PROMOTEUR_SCROLL_POSITION',
            {
              left: dossiersPromoteursDOM.scrollLeft,
              top: dossiersPromoteursDOM.scrollTop,
            },
          )
        } else {
          this.$store.commit(
            'userNavigationState/SET_DOSSIERS_PROMOTEUR_SCROLL_POSITION',
            null,
          )
        }
      }
    },
    dossierPromoteur: {
      deep: true,
      handler(val) {
        this.$store.commit(
          'dossierpromoteur/SET_DOSSIER_PROMOTEUR_SELECTED',
          val,
        )
      },
    },
    checked_all() {
      if (this.checked_all)
        this.dossierPromoteur.map((terrain) => (terrain.checked = true))
      else this.dossierPromoteur.map((terrain) => (terrain.checked = false))
    },
  },

  methods: {
    searchAfterDebounce: _.debounce(function (filters) {
      this.current_page = 1
      this.dossierPromoteur = []
      this.getDossierPromoteurs(
        filters,
        this.sort_list_terrains_by,
        this.sort_list_terrains_by_order,
      )
    }, 800),

    getDossierPromoteurs(filters, key = null, order = null) {
      this.isFetching = true
      this.$emit('setFilter', filters)
      this.$http
        .get(`admin/dossier-promoteurs?page=${this.current_page}`, {
          params: {
            status: Object.keys(this.status),
            key: key,
            order: order,
            ...filters,
            departement_id: this.filters.departement?.id,
            region_id: this.filters.region?.id,
            page_size_state: this.getDossiersPromoteurCurrentPage,
            surface_terrain: this.filters.surface_terrain,
            ca_potentiel: this.filters.ca_potentiel,
            availabe_when_brouillon: this.filters.availabe_when_brouillon
              ? this.filters.availabe_when_brouillon
              : '',
            vendu: this.filters.vendu ? this.filters.vendu : '',
          },
        })
        .then((response) => {
          this.$emit('setDataMeta', { meta: response.data.meta })
          this.archivedStatus = response.data.meta.archived_status
          let tab = response.data.data.map((folder) => ({
            ...folder,
            checked: false,
          }))
          this.dossierPromoteur.push(...tab)
          this.dossier_promoteur_selected.map((terrain) => {
            this.dossierPromoteur.map((dossier) => {
              if (terrain.id === dossier.id) dossier.checked = true
            })
          })
          this.$store.commit(
            'dossierpromoteur/SET_LIST_ADMINS',
            response.data.meta.list_admins,
          )
          this.current_page = response.data.meta.current_page
          this.last_page = response.data.meta.last_page
          this.isFetching = false
        })
    },

    getStatusColor(status) {
      switch (status.toString()) {
        case '2':
          return 'yellow'
        case '3':
          return 'green-100'
      }
    },

    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getDossierPromoteurs(
        this.filters,
        this.sort_list_terrains_by,
        this.sort_list_terrains_by_order,
      )
    },

    setSortingBy(val) {
      this.sorting_by = val
    },

    orderBy(key, order) {
      this.sort_list_terrains_by = key
      this.sort_list_terrains_by_order = order
      this.current_page = 1
      this.dossierPromoteur = []
      this.getDossierPromoteurs(this.filters, key, order)
    },

    displayModal(id) {
      this.$emit('displayModal', id)
    },

    // feature add responsable to terrain
    // displayModalTransfer(id) {
    //   this.$emit('displayModalTransfer', id)
    // },

    scrollPositionSaved() {
      if (this.isScrollPositionSaved) {
        const dossiersPromoteursDOM = document.getElementById('list_holder')
        setTimeout(() => {
          dossiersPromoteursDOM.scroll(
            this.scrollPosition.left,
            this.scrollPosition.top,
          )
        }, 2000)
      }
    },
    showTimer: _.debounce(function () {
      this.showAfterSecond = true
    }, 1200),
  },

  computed: {
    ...mapGetters({
      scrollPosition: 'userNavigationState/getDossiersPromoteurScrollPosition',
      isScrollPositionSaved:
        'userNavigationState/isDossiersPromoteurScrollPosition',
      dossier_promoteur_selected:
        'dossierpromoteur/getDossierPromoteurSelected',
    }),
    responsable: {
      get() {
        return this.$store.getters['dossierpromoteur/getSelectedResponsable']
      },
      set(value) {
        this.$store.commit('dossierpromoteur/SET_SELECTED_RESPONSABLE', value)
      },
    },
    sort_list_terrains_by: {
      get() {
        return this.$store.getters['dossierpromoteur/getSortListTerrainsBy']
      },
      set(value) {
        this.$store.commit('dossierpromoteur/SET_SORT_LIST_TERRAINS_BY', value)
      },
    },
    sort_list_terrains_by_order: {
      get() {
        return this.$store.getters[
          'dossierpromoteur/getSortListTerrainsByOrder'
        ]
      },
      set(value) {
        this.$store.commit(
          'dossierpromoteur/SET_SORT_LIST_TERRAINS_BY_ORDER',
          value,
        )
      },
    },
  },
}
</script>
